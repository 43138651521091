<template>
	<div class="mstp-map">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>青海省全息图</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>能耗全息图</li>
					</ul>
				</div>
			</div>
			<div class="tile">
				<div class="tile-title">
					<span></span>
					<span><i class="el-icon-more"></i></span>
				</div>
				<img src="@/assets/images/energyManage/map.jpg" alt="" />
				<div class="absolute-area">
					<!-- 西宁、海东、黄南、果洛 -->
					<div id="ab-xining" title="西宁" class="name" data="xining"></div>
					<div id="ab-haibei" title="海北" class="name" data="haibei"></div>
					<div id="ab-haidong" title="海东" class="name" data="haidong"></div>
					<div id="ab-haixi" title="海西" class="name" data="haixi"></div>
					<div id="ab-hainan" title="海南" class="name" data="hainan"></div>
					<div id="ab-yushu" title="玉树" class="name" data="yushu"></div>
					<div id="ab-huangnan" title="黄南" class="name" data="huangnan"></div>
					<div id="ab-guoluo" title="果洛" class="name" data="guoluo"></div>
					<div id="ab-geermu" title="格尔木" class="name" data="geermu"></div>
				</div>
			</div>
			<div class="item">
				<div class="box">
					<div class="box-title">
						<span>西宁 总体用电量</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>西宁 总体用油量</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>西宁 实时能耗</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
			</div>
			<div class="item">
				<div class="box">
					<div class="box-title">
						<span>西宁 用电量占比分析</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>西宁 用油量占比分析</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
			</div>
			<div class="item">
				<div class="box">
					<div class="box-title">
						<span>西宁 用电量占比分析</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>西宁 用油量占比分析</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>西宁 用油量占比分析</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>西宁 用油量占比分析</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
export default {
	components: {
		sidebar,
		headerTop,
	},
}
</script>

<style lang="scss" scoped>
.mstp-map {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.tile {
			position: relative;
			.tile-title {
				display: flex;
				margin-top: 20px;
				justify-content: space-between;
				color: #fff;
				padding: 0 10px;
				background: rgba(0, 0, 0, 0.65);
			}
			img {
				width: 100%;
				height: 100%;
			}
			.absolute-area {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 100;
				.name {
					position: absolute;
					width: 80px;
					height: 80px;
					border-radius: 40px !important;
					background-color: #f9e558;
					cursor: pointer;
					filter: alpha(opacity=50);
					-moz-opacity: 0.5;
					-khtml-opacity: 0.5;
					opacity: 0.5;
				}
				.name:hover {
					background: #63ec8d;
				}
				#ab-xining {
					left: 775px;
					top: 150px;
				}
				#ab-haibei {
					left: 665px;
					top: 90px;
				}
				#ab-haidong {
					left: 835px;
					top: 200px;
				}
				#ab-haixi {
					background-color: #f00;
					left: 415px;
					top: 150px;
				}
				#ab-hainan {
					left: 665px;
					top: 220px;
				}
				#ab-yushu {
					left: 445px;
					top: 380px;
				}
				#ab-huangnan {
					left: 785px;
					top: 290px;
				}
				#ab-guoluo {
					background-color: #ff7500;
					left: 665px;
					top: 330px;
				}
				#ab-geermu {
					background-color: #ff7500;
					left: 230px;
					top: 340px;
				}
			}
		}
		.item {
			display: flex;
			margin-top: 20px;
			.box {
				flex: 1;
				color: #fff;
				font-size: 14px;
				margin: 0 10px;
				.box-title {
					display: flex;
					justify-content: space-between;
					padding: 5px;
					background: rgba(0, 0, 0, 0.65);
				}
				.box-content {
					height: 300px;
					background: rgba(0, 0, 0, 0.35);
				}
			}
		}
	}
}
</style>
